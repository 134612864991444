<template>
  <div class="card dex-card">
    <div class="title-for-main-table">Token Live Pair Sniper 🚀</div>
    <div class="subtitle-for-main-table">
      Advance automated token sniper. Our token sniper listening to the token
      pair create event in BSC can capture all the token details in realtime Our
      advance token sniper will perform automated mini audit as soon as token
      created the liquidity pool
    </div>

    <div class="token-search-bar">
      <TokenSearchBar />
    </div>

    <b-row class="token-advance-filters">
      <b-col sm="12" md="4" lg="4">
        <div class="filter-fields-container mb-1">
          <b-form-select v-model="filterSelected" :options="filterFields" />
        </div>
      </b-col>

      <b-col sm="12" md="4" lg="4">
        <div class="filter-sort-by-container mb-1">
          <b-form-select v-model="selectedFilterOrder" :options="filterOrder" />
        </div>
      </b-col>

      <b-col sm="12" md="4" lg="4">
        <div class="filter-btn">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            pill
            @click="executeFilters()"
          >
            Filter
          </b-button>
        </div>
      </b-col>
    </b-row>

    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="recent-token-transactions">
          <b-table
            id="table-crypto"
            hover
            responsive
            :items="tokens"
            :fields="fields"
            class="mb-0 recent-tokens-table"
          >
            <template #cell(pairInfo)="data">
              <span class="click-pointer" @click="viewDetails(data.item)">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="data.item.tokenName | subStringGenerator(0, 1)"
                      class="token-pair-avatar"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap token-name">
                    WBNB/{{ data.item.tokenSymbol }}
                  </span>
                  <small class="token-address">{{
                    data.item.tokenAddress | truncateString(10)
                  }}</small>
                </b-media>
              </span>
            </template>

            <template #cell(createdAt)="data">
              <span
                class="tx-table-value text-nowrap token-listed-time token-data-value"
              >
                {{ data.value | formatDate("yyyy LLL dd ~ H:mm a") }}
              </span>
            </template>

            <template #cell(tokenPrice)="data">
              <span
                class="d-flex align-items-end justify-content-end token-data-value token-price"
              >
                {{ data.value | formatCurrency(12, 2) }}
              </span>
            </template>

            <template #cell(tokenMarketCap)="data">
              <span
                class="d-flex align-items-end justify-content-end token-market-cap token-data-value"
              >
                {{ data.value | formatCurrency(2, 12) }}
              </span>
            </template>

            <template #cell(tokenLiquidityPoolTotal)="data">
              <span
                class="d-flex align-items-end justify-content-end token-liquidity-pool token-data-value"
              >
                {{ data.value | formatCurrency(2, 12) }}
              </span>
            </template>

            <template #cell(riskLevel)="data">
              <span class="token-risk-level">
                <RiskLevelInfo :token-address="data.item.tokenAddress" />
              </span>
            </template>

            <!-- <template #cell(tokenChain)="data">
              <span class="token-chain">
                <b-badge pill variant="light-success">{{ data.value }}</b-badge>
              </span>
            </template> -->
            <!-- Optional default data cell scoped slot -->
            <template #cell(actions)="data">
              <span class="action-icons">
                <a
                  :href="`https://pancakeswap.finance/swap?outputCurrency=${data.item.tokenAddress}`"
                  target="_blank"
                >
                  <b-img
                    src="@/assets/icons/pancakeswap.png"
                    width="20"
                    height="20"
                    rounded="circle"
                  />
                </a>
              </span>
              <span class="action-icons">
                <a
                  :href="`https://bscscan.com/token/${data.item.tokenAddress}`"
                  target="_blank"
                >
                  <b-img
                    src="@/assets/icons/bsc.svg"
                    width="20"
                    height="20"
                    rounded="circle"
                  />
                </a>
              </span>
              <span class="action-icons" @click="viewDetails(data.item)">
                <a href="#">
                  <b-img
                    src="@/assets/icons/telescope.png"
                    width="20"
                    height="20"
                    rounded="circle"
                  />
                </a>
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BTable,
  BImg,
  BAvatar,
  BMedia,
  // BBadge,
  BFormSelect,
  BButton,
  BRow,
  BCol
} from "bootstrap-vue";
import { mapState } from "vuex";
import {
  truncateString,
  formatCurrency,
  formatNumber,
  subStringGenerator,
  formatDate
} from "../../filters";
import TokenSearchBar from "./TokenSearchBar.vue";
import RiskLevelInfo from "./RiskLevelInfo.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormSelect,
    BSpinner,
    BTable,
    BImg,
    BAvatar,
    BMedia,
    // BBadge,
    BButton,
    BRow,
    BCol,
    TokenSearchBar,
    RiskLevelInfo
  },
  directives: {
    Ripple
  },
  data() {
    return {
      fields: [
        {
          key: "pairInfo",
          label: "Pair Info",
          class: "token-pair-data-call"
        },
        {
          key: "createdAt",
          label: "Listed Since",
          class: "token-pair-data-call"
        },
        {
          key: "tokenPrice",
          label: "Token Price",
          class: "token-pair-data-call text-right"
        },
        {
          key: "tokenMarketCap",
          label: "Market Cap",
          class: "token-pair-data-call text-right"
        },
        {
          key: "riskLevel",
          label: "Risk Level",
          class: "token-pair-data-call text-center"
        },
        {
          key: "tokenLiquidityPoolTotal",
          label: "Initial Liquidity",
          class: "token-pair-data-call text-center"
        },
        // {
        //   key: "tokenChain",
        //   label: "Chain",
        //   class: "token-pair-data-call"
        // },
        // {
        //   key: "tokenChain",
        //   label: "Exchange",
        //   class: "token-pair-data-call"
        // },
        {
          key: "actions",
          label: "Actions",
          class:
            "token-pair-data-call text-right align-end justify-content-end"
        }
      ],
      items: [],
      filterSelected: null,
      filterFields: [
        { value: "null", text: "Please select a filter field" },
        { value: "risk_level", text: "Filter By Risk Level" },
        { value: "token_market_cap", text: "Filter By Market cap" },
        { value: "token_total_supply", text: "Filter By Total Supply" },
        { value: "token_price_usd", text: "Filter By Token Price" },
        {
          value: "token_liquidity_pool_total",
          text: "Filter By Liquidity Pool Amount"
        }
      ],
      filterOrder: [
        { value: "null", text: "Please select filtration order" },
        { value: "ASC", text: "Low to High" },
        { value: "DESC", text: "High to Low" }
      ],
      selectedFilterOrder: null
    };
  },
  computed: {
    ...mapState("token", {
      tokens: (state) => state.tokens,
      isLoading: (state) => state.isLoadingTable
    })
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber,
    subStringGenerator,
    formatDate
  },
  mounted() {
    const paginationOption = {
      page: 1,
      limit: 100,
      filterField: this.filterSelected,
      direction: this.selectedFilterOrder
    };

    this.$store.dispatch("token/getTokenData", paginationOption);
    // this.sockets.unsubscribe("risk-level-to-client");
    // this.sockets.subscribe("risk-level-to-client", (data) => {
    //   console.log("risk-level-to-client", data);
    //   //this.msg = data.message;
    // });
  },
  unmounted() {
    // this.sockets.unsubscribe("risk-level-to-client");
  },
  methods: {
    viewDetails(data) {
      this.$router.push({
        path: `/ape-details/${data.tokenAddress}`,
        params: {
          id: data.token
        }
      });
    },
    executeFilters() {
      console.log("hi filter clicked");
      const paginationOption = {
        page: 1,
        limit: 100,
        filterField: this.filterSelected,
        direction: this.selectedFilterOrder
      };

      this.$store.dispatch("token/getTokenData", paginationOption);
    }
  }
};
</script>
<style  lang="scss" scoped>
  .dark-layout .table {
    background-color: #0c0d20 !important;
  }
  .recent-tokens-table {
    background-color: #0c0d20 !important;
  }
</style>
