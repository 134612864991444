<template>
  <div>
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <TokenListingTable />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TokenListingTable from "./ape/TokenListingTable.vue";
import { BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    TokenListingTable
  },
  data() {
    return {};
  },
  directives: {
    Ripple
  },
  methods: {
    showTokenToast(data) {
      const payload = JSON.parse(data);
      let description = `${payload.token_name} ~ ${payload.token_symbol} just listed on ${payload.token_exchange}`;
      let link = "/ape-details/" + payload.token_address;
      this.$bvToast.toast(description, {
        title: `New pair just discoverd`,
        variant: "primary",
        href: link,
        solid: true,
        "append-toast": true
      });
    }
  },
  mounted() {
    // this.sockets.subscribe("token", (data) => {
    //   console.log("new event arrived");
    //   this.showTokenToast(data);
    //   this.msg = data.message;
    // });
  },
  beforeMount() {
    // this.sockets.subscribe("token", (data) => {
    //   console.log("new event arrived");
    //   // this.showTokenToast(data);
    //   // this.msg = data.message;
    // });
  },
  unmounted() {
    // this.sockets.unsubscribe("token");
  },
  beforeDestroy() {
    // this.sockets.unsubscribe("token");
  },
  computed: {}
};
</script>
